import { entries } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { toChecksumAddress } from 'ethereum-checksum-address';

import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { ILeveragedPosition } from '../../types/uniswapTokens';
import { useLeveragedPositionsQuery } from './useLeveragedPositionsQuery';
import { IMarketData } from './useMarketsQuery';

const INITIAL_DATA: ILeveragedPosition[] = [];

const getMarket = (marketData: IMarketData[], leverageContractAddress: string) => {
  return marketData.find((item) => item.addresses.leverageAddress === leverageContractAddress);
}

export const useLeveragedPositionsDataQuery = (addresses?: string[], invertPool?: boolean) => {
  const [chainId, isMarketsReady, marketData] = useRootStore((store) => [
    store.currentChainId,
    store.isMarketsReady,
    store.getCurrentChainMarketData(),
  ]);
  const { uiLeveragePositionService } = useSharedDependencies();
  const leveragedPositionsQuery = useLeveragedPositionsQuery();

  const requestedAddresses = !addresses?.length
    ? leveragedPositionsQuery.data.map((item) => item.position_address)
    : addresses;

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.LEVERAGED_POSITIONS_DATA, chainId, requestedAddresses],
    queryFn: () => {
      const requestData = leveragedPositionsQuery.data.reduce<
        Record<string, { market: IMarketData, addresses: string[] }>
      >((acc, position) => {
        if (requestedAddresses.includes(position.position_address)) {
          const market = getMarket(marketData, position.leverage_contract_address);
          if (market) {
            const dataProviderAddress = market.addresses.leverageDataProviderAddress;
            if (acc[dataProviderAddress]) {
              acc[dataProviderAddress].addresses.push(position.position_address)
            } else {
              acc[dataProviderAddress] = {
                market,
                addresses: [position.position_address],
              }
            }
          }
        }
        return acc;
      }, {});

      const requests = entries(requestData).map(([dataProviderAddress, { market, addresses }]) => {
        const requestFunc = async () => {
          const marketResponse = await uiLeveragePositionService.getPositionsData(chainId, dataProviderAddress, addresses);
          return { market, addresses, marketResponse };
        };
        return requestFunc();
      });

      return Promise.all(requests);
    },
    enabled: isMarketsReady && !leveragedPositionsQuery.isLoading,
    refetchInterval: POLLING_INTERVAL,
    keepPreviousData: !Boolean(addresses?.length),
    select: (response) => response.reduce<ILeveragedPosition[]>((acc, { market, addresses, marketResponse }) => {
      marketResponse.forEach((item, index) => acc.push({
          ...item,
          uniswapV3Position: {
            ...item.uniswapV3Position,
            marketId: market?.marketId,
            marketName: market?.marketName,
            tokenId: item.uniswapV3Position.tokenId.toNumber(),
            token0: invertPool ? item.uniswapV3Position.token1 : item.uniswapV3Position.token0,
            token1: invertPool ? item.uniswapV3Position.token0 : item.uniswapV3Position.token1,
            fee0: invertPool ? item.uniswapV3Position.fee1.toString() : item.uniswapV3Position.fee0.toString(),
            fee1: invertPool ? item.uniswapV3Position.fee0.toString() : item.uniswapV3Position.fee1.toString(),
            amount0: invertPool ? item.uniswapV3Position.amount1.toString() : item.uniswapV3Position.amount0.toString(),
            amount1: invertPool ? item.uniswapV3Position.amount0.toString() : item.uniswapV3Position.amount1.toString(),
            tickCurrent: item.uniswapV3Position.tickCurrent,
            sqrtPriceX96: item.uniswapV3Position.sqrtPriceX96,
          },
          debtAsset: toChecksumAddress(item.debtAsset),
          positionAddress: addresses[index],
          debt: item.debt.toString(),
          revenueFee0: invertPool ? item.revenueFee1.toString() : item.revenueFee0.toString(),
          revenueFee1: invertPool ? item.revenueFee0.toString() : item.revenueFee1.toString(),
          revenueFeePercent: item.revenueFeePercent.toString(),
      }));
      return acc;
    }, []),
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
