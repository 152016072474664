import { entries } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { toChecksumAddress } from 'ethereum-checksum-address';

import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { IPosition } from '../../types/uniswapTokens';
import { IMarketData } from './useMarketsQuery';
import { useAppDataContext } from '../app-data-provider/useAppDataProvider';

const INITIAL_DATA: IPosition[] = [];

const getMarket = (marketData: IMarketData[], positionWrapperAddress: string) => {
  return marketData.find(
    (item) => item.addresses.positionWrapperAddress === toChecksumAddress(positionWrapperAddress)
  );
}

export const useSuppliedPositionsDataQuery = (tokenIds?: string[]) => {
  const { user, loading } = useAppDataContext();
  const { uiPositionService } = useSharedDependencies();
  const [chainId, isMarketsReady, marketData] = useRootStore((store) => [
    store.currentChainId,
    store.isMarketsReady,
    store.getCurrentChainMarketData(),
  ]);

  const userERC1155ReservesData = user.userERC1155ReservesData;
  const suppliedTokenIds = tokenIds
    ? tokenIds
    : userERC1155ReservesData.map((item) => item.tokenId);

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.SUPPLIED_POSITIONS_DATA, chainId, suppliedTokenIds],
    queryFn: () => {
      const requestData = userERC1155ReservesData
        .filter((item) => suppliedTokenIds.includes(item.tokenId))
        .reduce<Record<string, { market: IMarketData, tokenIds: string[] }>>((acc, reserve) => {
          const market = getMarket(marketData, reserve.asset);
          if (market) {
            const dataProviderAddress = market.addresses.dataProviderAddress;
            if (acc[dataProviderAddress]) {
              acc[dataProviderAddress].tokenIds.push(reserve.tokenId);
            } else {
              acc[dataProviderAddress] = {
                market,
                tokenIds: [reserve.tokenId],
              };
            }
          }
          return acc;
        }, {});

      const requests = entries(requestData).map(([dataProviderAddress, { market, tokenIds }]) => {
        const requestFunc = async () => {
          const marketResponse = await uiPositionService.getPositionsData(chainId, dataProviderAddress, tokenIds);
          return { market, marketResponse };
        };
        return requestFunc();
      });

      return Promise.all(requests);
    },
    enabled: !loading && isMarketsReady,
    refetchInterval: POLLING_INTERVAL,
    keepPreviousData: true,
    select: (response) => response.reduce<IPosition[]>((acc, { market, marketResponse }) => {
      marketResponse.forEach((item) => {
        acc.push({
          ...item,
          marketId: market?.marketId,
          marketName: market?.marketName,
          tokenId: item.tokenId.toNumber(),
          fee0: item.fee0.toString(),
          fee1: item.fee1.toString(),
          amount0: item.amount0.toString(),
          amount1: item.amount1.toString(),
        })
      });
      return acc;
    }, []),
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
